<template>
  <div>
    <SchoolLeaveEntitlementTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @navigateToIndividual="goToTeacherEntitlement($event.item)"
      @row-clicked="goToTeacherEntitlement"
    >
    </SchoolLeaveEntitlementTable>
  </div>
</template>

<script>
import SchoolLeaveEntitlementTable from "@/modules/school/components/management/leave/entitlement/SchoolLeaveEntitlementTable";
import { mapGetters } from "vuex";
export default {
  name: "TeacherLeaveEntitlement",
  components: { SchoolLeaveEntitlementTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  beforeDestroy() {
    this.$store.commit("setTeacherList", []);
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherList")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Navigate to Teacher individual entitlement list
     *
     * @param data
     */
    goToTeacherEntitlement(data) {
      console.log(data);
      this.$router.push({
        name: "teacherEntitlementPage",
        query: { id: data.id, name: data.name },
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherList",
      pagination: "getTeacherListPagination",
      isBusyStore: "getTeacherListBusy",
    }),
  },
};
</script>

<style scoped></style>
